import { CSSProperties, Fragment, memo, useContext, useState } from "react"
import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import Title from "./Title"
import { fieldIncompleteContactForm, fieldIncompleteQuotatorForm, initialContactForm, initialQuotatorForm } from "../utils/Jsons"
import { WorkOpeningTypes, WorkStatusTypes } from "../interfaces/ContactInterfaces"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 100%;
    min-height: 70vh;
    padding-top: 20vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;

`

const ContentContainer = styled.div`
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;

    & > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        & > hr {
            width: 30px;
            border: none;
        }

        @media ${device.md} {
            flex-direction: column;
            & > hr {
                height: 20px;
            }
        }

        & > h2 {
            margin-bottom: 20px;
        }

        & > p {
            color: #2E2D2D;
            margin-top: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;

            @media ${device.lg} {
                width: 65%;
            }

            @media ${device.md} {
                width: 85%;
            }

            @media ${device.sm} {
                font-size: 12px;
                line-height: 24px;
                width: 90%;
            }
        }

        @media ${device.sm} {
            & > h2 {
                font-size: 18px;
                text-align: center;

            }
        }
        
    }

    @media ${device.md} {
        width: 80%;
    }

    @media ${device.sm} {
    }

    @media ${device.xs} {
    }
`

const InputContainer = styled.div<{ screen: string }>`
    width: 60%;
    max-width: 700px;
    position: relative;
    display: flex;
    justify-content: center;

    margin-top: 25px;
    margin-bottom: 25px;

    ${({ screen }) => screen === 'Contact' && css`
        margin-top: 30px;
        margin-bottom: 50px;
    `}


    @media ${device.md} {
        width: 80%;
    }
`

const Input = styled.input<{ incomplete: string }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #b7babc;
    border-radius: 8px;
    box-shadow: none;
    height: 42px !important;
    padding-left: 20px;
    opacity: 1 !important;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} {
    }

    ${({ incomplete }) => incomplete === 'true' && css`
        border-color: #ff7071;
        background-color: #fff0f0;
        box-shadow: 0 0 0 1px #ff7071 inset;
    `}

    &:focus {
        outline: 0 none;
        border-color: #4baf51; 
    }

    label {
        color: #2c3357;
        font-size: 13px;
        font-weight: 400;
        left: 5px;
        position: absolute;
        top: -25px;
    }
`

const Select = styled.select`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #b7babc;
    border-radius: 8px;
    box-shadow: none;
    height: 46px !important;
    padding-left: 20px;
    opacity: 1 !important;

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} {
    }

    &:focus {
        outline: 0 none;
        border-color: #4baf51;
    }

    label {
        opacity: 1 !important;
        color: #2c3357;
        font-size: 13px;
        font-weight: 400;
        left: 5px;
        position: absolute;
        top: -25px;
    }
`

const Label = styled.label<{ color: string }>`
    color: #333;
    color: ${({ color }) => color};
    font-size: 13px;
    font-weight: 400;
    left: 5px;
    position: absolute;
    top: -25px;
    opacity: 1 !important;

`

const SendButton = styled.button`
    position: relative;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 3px;
    color: white;
    background-color: #ff0c19;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 30px;
    border-radius: 5px;
    margin-top: 50px;
    opacity: 1 !important;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        background-color: #54c35c;

        &::before {
            animation: fillButton 0.3s ease-in-out forwards;
        }
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 15px 25px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

const Icon = styled.img`
    width: 20px;
    height: auto;
    margin-right: 10px;

`

interface Props {
    screen: 'Contact' | 'Quotator';
}

const FormComponent = ({ screen }: Props) => {

    const {
        theme: { colors },
    } = useContext(ThemeContext);

    const { handleLogEvent } = useLogEvent();

    const [form, setForm] = useState<any>(
        screen === 'Contact' ? initialContactForm : initialQuotatorForm
    )

    const [fieldIncomplete, setFieldIncomplete] = useState<any>(
        screen === 'Contact' ? fieldIncompleteContactForm : fieldIncompleteQuotatorForm
    )

    const formValidations = () => {

        let existError: boolean = false;

        if (screen === 'Contact') {

            setFieldIncomplete({
                ...fieldIncomplete,
                message: form.message.trim() === '',
            });

            if (form.message.trim() === '') {
                existError = true;
            }

        }

        if (screen === 'Quotator') {

            setFieldIncomplete({
                ...fieldIncomplete,
                name: form.name.trim() === '',
                email: form.email.trim() === '',
                phone: form.phone.trim() === '',
                locality: form.locality.trim() === '',
            });

            if (form.name.trim() === '') {
                existError = true;
            }
            if (form.email.trim() === '') {
                existError = true;
            }
            if (form.phone.trim() === '') {
                existError = true;
            }
            if (form.locality.trim() === '') {
                existError = true;
            }

        }



        return existError;

    }

    const handleFormConfirm = async (e: any) => {

        e.preventDefault();

        if (!formValidations()) {

            setForm(screen === 'Contact' ? initialContactForm : initialQuotatorForm)

            setFieldIncomplete(screen === 'Contact' ? fieldIncompleteContactForm : fieldIncompleteQuotatorForm)

            let message = '';

            if (screen === 'Contact') {

                message = `https://wa.me/+5491164224433?text=Hola Aluviher! Me contacto mediante su pagina. Tengo una consulta: %0a %0a "${form.message}" %0a %0a Aguardo a su respuesta. %0a ¡Muchas gracias!`;

                handleLogEvent('Click_boton_formulario_whatsapp_contacto');

            }

            if (screen === 'Quotator') {

                message = `https://wa.me/+5491164224433?text=Hola Aluviher! Me contacto mediante su pagina. Quiero consultar por una cotización: %0a %0a - Nombre y apellido: *${form.name}* %0a - Correo: *${form.email}* %0a - Teléfono: *${form.phone}* %0a - Localidad: *${form.locality}* %0a - Estado de obra: *${form.workStatus}* %0a - Tipo de abertura: *${form.workOpening}* ${form.message.trim() !== '' ? `%0a - Mensaje: *${form.message}*` : ''} %0a %0a Aguardo a su respuesta. %0a ¡Muchas gracias!`;

                handleLogEvent('Click_boton_formulario_whatsapp_cotizador');

            }

            window.open(message, 'blank')

        }

    }

    const { t } = useTranslation();

    const styleCompleted: CSSProperties = {
        outline: '0 none',
        borderColor: '#4baf51',
        backgroundColor: colors.background_light,
        color: colors.text
    }

    return (

        <Container id={screen === 'Contact' ? "contact" : "quotator"}>

            <ContentContainer>

                <FormContainer>

                    <div>

                        <Title
                            title={screen === 'Contact' ? t('contact.title.1') : t('contact.title.2')}
                            subtitle={screen === 'Contact'
                                ? t('contact.subtitle.1')
                                : t('contact.subtitle.2')
                            }
                        />

                        {
                            screen === 'Quotator' && (

                                <Fragment>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.fullname')} *</Label>

                                        <Input

                                            className="utils__fade-in-fast-top"
                                            type={"text"}
                                            placeholder={t('contact.label.fullname')}
                                            value={form.name}
                                            incomplete={fieldIncomplete.name.toString()}
                                            onChange={(e: any) => {

                                                setFieldIncomplete({ ...fieldIncomplete, name: false })
                                                setForm({
                                                    ...form,
                                                    name: e.target.value
                                                })
                                            }}
                                            style={
                                                form.name.trim() !== '' ? { ...styleCompleted } : {
                                                    backgroundColor: colors.background_light,
                                                    color: colors.text
                                                }

                                            }

                                        ></Input>

                                    </InputContainer>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.email')} *</Label>

                                        <Input
                                            className="utils__fade-in-fast-top"
                                            type={"text"}
                                            placeholder={t('contact.label.email')}
                                            value={form.email}
                                            incomplete={fieldIncomplete.email.toString()}
                                            onChange={(e: any) => {

                                                setFieldIncomplete({ ...fieldIncomplete, email: false })
                                                setForm({
                                                    ...form,
                                                    email: e.target.value
                                                })
                                            }}
                                            style={form.email.trim() !== '' ? { ...styleCompleted } : {
                                                backgroundColor: colors.background_light,
                                                color: colors.text
                                            }}
                                        ></Input>

                                    </InputContainer>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.phone')} *</Label>

                                        <Input
                                            className="utils__fade-in-fast-top"
                                            type={"text"}
                                            placeholder={t('contact.label.phone')}
                                            value={form.phone}
                                            incomplete={fieldIncomplete.phone.toString()}
                                            onChange={(e: any) => {

                                                setFieldIncomplete({ ...fieldIncomplete, phone: false })
                                                setForm({
                                                    ...form,
                                                    phone: e.target.value
                                                })
                                            }}
                                            style={form.phone.trim() !== '' ? { ...styleCompleted } : {
                                                backgroundColor: colors.background_light,
                                                color: colors.text
                                            }}
                                        ></Input>

                                    </InputContainer>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.city')} *</Label>

                                        <Input
                                            className="utils__fade-in-fast-top"
                                            type={"text"}
                                            placeholder={t('contact.label.city')}
                                            value={form.locality}
                                            incomplete={fieldIncomplete.locality.toString()}
                                            onChange={(e: any) => {

                                                setFieldIncomplete({ ...fieldIncomplete, locality: false })
                                                setForm({
                                                    ...form,
                                                    locality: e.target.value
                                                })
                                            }}
                                            style={form.locality.trim() !== '' ? { ...styleCompleted } : {
                                                backgroundColor: colors.background_light,
                                                color: colors.text
                                            }}
                                        ></Input>

                                    </InputContainer>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.status')} *</Label>

                                        <Select
                                            style={{
                                                backgroundColor: colors.background_light,
                                                color: colors.text
                                            }}
                                            className="utils__fade-in-fast-top"
                                            placeholder={t('contact.label.status')}
                                            value={form.workStatus}
                                            onChange={(e: any) => {

                                                setForm({
                                                    ...form,
                                                    workStatus: e.target.value
                                                })
                                            }}
                                        >
                                            <option value={'Proyecto' as WorkStatusTypes}>{t('contact.label.status.option.1')}</option>
                                            <option value={'Grueso' as WorkStatusTypes}>{t('contact.label.status.option.2')}</option>
                                            <option value={'Vanos listos' as WorkStatusTypes}>{t('contact.label.status.option.3')}</option>
                                            <option value={'Recambio' as WorkStatusTypes}>{t('contact.label.status.option.4')}</option>

                                        </Select>

                                    </InputContainer>

                                    <InputContainer screen={screen}>

                                        <Label color={colors.text_light} className="utils__fade-in-fast-top">{t('contact.label.opening')} *</Label>

                                        <Select
                                            style={{
                                                backgroundColor: colors.background_light,
                                                color: colors.text
                                            }}
                                            className="utils__fade-in-fast-top"
                                            placeholder={t('contact.label.opening')}
                                            value={form.workOpening}
                                            onChange={(e: any) => {

                                                setForm({
                                                    ...form,
                                                    workOpening: e.target.value
                                                })
                                            }}
                                        >
                                            <option value={'PVC Rehau' as WorkOpeningTypes}>{t('contact.label.opening.option.1')}</option>
                                            <option value={'Aluminio Hydro' as WorkOpeningTypes}>{t('contact.label.opening.option.2')}</option>
                                            <option value={'Ambas' as WorkOpeningTypes}>{t('contact.label.opening.option.3')}</option>

                                        </Select>

                                    </InputContainer>

                                </Fragment>

                            )
                        }

                        <InputContainer screen={screen}>

                            <Label color={colors.text_light} className="utils__fade-in-fast-top">{screen === 'Contact' ? `${t('contact.label.query.1')} *` : t("contact.label.query.2")}</Label>

                            <Input
                                className="utils__fade-in-fast-top"
                                type={"text"}
                                placeholder={screen === 'Contact' ? `${t("contact.label.query.1")} *` : t("contact.label.query.2")}
                                value={form.message}
                                incomplete={screen === 'Contact' ? fieldIncomplete.message.toString() : ''}
                                onChange={(e: any) => {

                                    setFieldIncomplete({ ...fieldIncomplete, message: false })
                                    setForm({
                                        ...form,
                                        message: e.target.value
                                    })
                                }}
                                style={form.message.trim() !== '' ? { ...styleCompleted } : {
                                    backgroundColor: colors.background_light,
                                    color: colors.text
                                }}
                            ></Input>


                        </InputContainer>

                        {
                            screen === 'Contact' && (

                                <p style={{ color: colors.text_light }} className="utils__fade-in-fast-top">{t('contact.aclaration')}</p>

                            )
                        }

                    </div>

                    <SendButton
                        style={{ backgroundColor: screen === 'Contact' ? '#ff0c19' : '#004bb5' }}
                        className="utils__fade-in-fast-top"
                        onClick={(e) => handleFormConfirm(e)}
                    >
                        <Icon src={require('../images/whatsapp.png')} alt="whtasapp" />

                        {screen === 'Contact' ? t('contact.button.1') : t('contact.button.2')}
                    </SendButton>

                </FormContainer>

            </ContentContainer>


        </Container>

    )
}

const Form = memo(FormComponent);

export default Form;