import { memo, useState } from "react";
import { useLogEvent } from "../hooks/useLogEvent";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import FloatingChatButton from "./FloatingChatButton";
import ChatInterface from "./ChatInterface";

const WsButton = styled.div`
    border-radius: 50px;
    background: #25D366;
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

    & > img {
        width: 30px;
        height: 30px;
        padding-left: 2px;
    }

    @media ${device.sm} {
        right: 30px;
    }

`

const WsFloatButtonComponent = () => {

    const { handleLogEvent } = useLogEvent()

    const [isChatOpen, setIsChatOpen] = useState(false)

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen)
    }

    return (

        // <WsButton
        //     className="utils__fade-in-fast-left"
        //     target="_blank"
        //     href="https://wa.me/+5491164224433?text=Hola Aluviher! Me contacto mediante su pagina. Quiero hacer una consulta."
        //     rel="noreferrer"
        //     onClick={() => {
        //         handleLogEvent('Click_boton_whatsapp_flotante');
        //     }}
        // >

        //     <img
        //         loading="lazy"
        //         src={require('../images/whatsapp.webp')}
        //         alt='Logo WhatsApp'
        //     />

        // </WsButton>
        <WsButton>
            <FloatingChatButton onClick={toggleChat} isOpen={isChatOpen} />
            <ChatInterface isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
        </WsButton>
    )
}

const WsFloatButton = memo(WsFloatButtonComponent);

export default WsFloatButton;
