import { useState, useEffect } from "react"
import { Bot } from "lucide-react"

interface FloatingChatButtonProps {
    onClick: () => void
    isOpen: boolean
}

export default function FloatingChatButton({ onClick, isOpen }: FloatingChatButtonProps) {
    const [isTextVisible, setIsTextVisible] = useState(true)

    useEffect(() => {
        if (isOpen) return

        const showText = () => {
            setIsTextVisible(true)
            setTimeout(() => {
                setIsTextVisible(false)
            }, 5000) // Text visible for 5 seconds
        }

        showText() // Initial show

        const interval = setInterval(() => {
            showText()
        }, 10000) // Full cycle: 5s visible + 10s hidden

        return () => clearInterval(interval)
    }, [isOpen])

    return (
        <button
            onClick={onClick}
            className={`fixed bottom-6 right-6 flex items-end transition-all duration-300 ease-in-out ${isOpen ? "opacity-0 pointer-events-none" : "opacity-100"
                }`}
            aria-label="Abrir chat"
        >
            <div
                className={`speech-bubble text-[14px] sm:text-base bg-white text-black py-3 px-4 rounded-2xl shadow-lg mr-4 transition-all duration-300 ${isTextVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
                    }`}
            >
                ¿En qué puedo ayudarte?
            </div>
            <div className="bg-green-500 text-white p-3 rounded-full shadow-lg flex items-center justify-center">
                <Bot size={24} />
            </div>
        </button>
    )
}

