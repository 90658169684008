import styled from "styled-components"
import { memo, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Image = styled.img`
    width: 100%;
    padding-top: 100px;
    object-fit: contain;

    @media ${device.lg} {
    padding-top: 70px;
    }
`

const ProductBannerComponent = ({ image }: any) => {

    const location = useLocation();

    useEffect(() => {
        allAnimations();

        return () => {
            allAnimations()
        };

    }, [location.pathname])

    return (

        <Container id="product">

            <Image src={`${process.env.REACT_APP_CDN}/${image}`} alt={image} loading="eager" className="utils__fade-in-fast-top" />

        </Container>

    )
}

const ProductBanner = memo(ProductBannerComponent);

export default ProductBanner;
