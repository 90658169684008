import Introduction from "../components/Introduction"
import { Suspense, lazy, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import LoadingComponent from "../components/LoadingComponent"
import WorkModal from "../components/WorkModal";
import Descriptions from "../components/Descriptions";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../contexts/theme/ThemeContext";
import { useSelector } from "react-redux";
import { getFullDate } from "../utils/Utilities";
import Building from "../components/Building";

const JobsGridHome = lazy(() => import("../components/JobsGridHome"));

export const HomeScreen = () => {

    const location = useLocation()

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const {
        theme,
    } = useContext(ThemeContext);

    const { i18n } = useTranslation();

    const { loading } = useSelector((state: any) => state.ui);

    const preloader = () => {

        if (!loading) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 1000);

        }

    };

    useEffect(() => {

        preloader();
        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme, loading])

    const [showModal, setShowModal] = useState<null | { id: number, image: number }>(null);


    const [showPreloader, setShowPreloader] = useState(true);

    return (

        <ScreenContainer>

            {showPreloader && <Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <Introduction />

            {showModal !== null && <WorkModal showModal={showModal} setShowModal={setShowModal} />}

            <Suspense fallback={<LoadingComponent />}>

                <Descriptions />

                <JobsGridHome setShowModal={setShowModal} />

            </Suspense>

        </ScreenContainer>

    )
}
