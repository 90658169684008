import { Suspense, lazy, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ProductIntro from "../components/ProductIntro";
import { allAnimations } from "../effects/Observers"
import LoadingComponent from "../components/LoadingComponent"
import PvcColors from "../components/PvcColors";
import ProfilesSection from "../components/ProfilesSection";
import ProductItem from "../components/ProductItem";
import { useTranslation } from "react-i18next";
import ScreenContainer from "../components/ScreenContainer";
import { ThemeContext } from "../contexts/theme/ThemeContext";
import ProductSection from "../components/ProductSection";
import ProductBanner from "../components/ProductBanner";

export const ProductScreen = () => {

    const location = useLocation()

    const path = location.pathname;

    const { t } = useTranslation();
    const pvcData: any = t('product.pvc', { returnObjects: true });
    const aluminioData: any = t('product.aluminio', { returnObjects: true });

    const getTexts = () => {

        if (path.includes('pvc')) {
            return pvcData;
        }
        if (path.includes('aluminio')) {
            return aluminioData;
        }

        return aluminioData
    }

    const {
        theme,
    } = useContext(ThemeContext);

    const [texts] = useState(getTexts());

    const { i18n } = useTranslation();

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme])

    if (path.includes('herrajes')) {
        return (
            <ScreenContainer>
                <ProductItem
                    type="herrajes"
                    textPosition="left"
                    goTo="/productos/herrajes"
                    style={{
                        margin: '20vh 0vw 0vh'
                    }}
                />
            </ScreenContainer>
        );
    }

    return (

        <ScreenContainer>

            <ProductBanner
                image={texts?.mainSection.banner.toString()}
            />

            <ProductIntro
                title={texts?.mainSection.title}
                image={texts?.mainSection.image}
                description={texts?.mainSection.description}
                systems={texts?.mainSection.systems}
            />

            <Suspense fallback={<LoadingComponent />}>

                {
                    texts?.firstData.map((value: any) => {

                        return (
                            <ProductSection
                                key={value.title}
                                title={value.title}
                                subtitle={value.description}
                                image={value.image}
                            />
                        )
                    })
                }

                {
                    texts?.profiles.map((value: any) => {

                        return (

                            <ProfilesSection
                                title={value.title}
                                profiles={value.profiles}
                            />
                        )
                    })
                }

                {
                    texts?.lastData.map((value: any) => {

                        return (
                            <ProductSection
                                key={value.title}
                                title={value.title}
                                subtitle={value.description}
                                image={value.image}
                            />
                        )
                    })
                }


                <ProductSection
                    title={texts?.colors.title}
                    subtitle={texts?.colors.description}
                    image={undefined}
                    children={<PvcColors data={texts.colors.colors} />}

                />



            </Suspense>

        </ScreenContainer>

    )
}
