
export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatMonth(date.getMonth() + 1);
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const miliseconds = date.getMilliseconds();
    
    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;
    
}

const formatMonth = (month) => {

    let m = month;

    if (m < 10) {
        m = `0${m}`;
    }

    return m;

}

export const formatPrice = (price) => {

    let p = price;
    const internationarFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 })

    p = internationarFormat.format(p);

    return p;

} 