import type React from "react";
import { useState, useEffect, useRef } from "react";
import { Send, X } from "lucide-react";
import { useLogEvent } from "../hooks/useLogEvent";

interface Message {
    id: number;
    text: string;
    isUser: boolean;
}

interface ChatInterfaceProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function ChatInterface({ isOpen, onClose }: ChatInterfaceProps) {
    const [inputValue, setInputValue] = useState("");
    const [messages, setMessages] = useState<Message[]>([]);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const { handleLogEvent } = useLogEvent();

    useEffect(() => {
        if (isOpen && messages.length === 0) {
            setMessages([
                {
                    id: 1,
                    text: "¡Hola, mi nombre es Agustín! ¿En qué puedo ayudarte?",
                    isUser: false,
                },
            ]);
        }
    }, [isOpen, messages.length]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, isOpen]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSendMessage = (e: React.FormEvent) => {
        e.preventDefault();
        if (inputValue.trim() === "") return;

        const newMessage: Message = {
            id: Date.now(),
            text: inputValue,
            isUser: true,
        };

        setMessages((prev) => [...prev, newMessage]);
        setInputValue("");

        setTimeout(() => {
            const responseMessage: Message = {
                id: Date.now() + 1,
                text: "Gracias por tu mensaje. Te vamos a redirigir a WhatsApp.",
                isUser: false,
            };
            setMessages((prev) => [...prev, responseMessage]);

            setTimeout(() => {
                const message = `https://wa.me/+5491164224433?text=${inputValue}`;
                handleLogEvent('Click_boton_bot_whatsapp');
                window.open(message, 'blank');
            }, 2000);
        }, 1000);
    };

    return (
        <div
            className={`fixed bottom-8 right-6 bg-white rounded-lg shadow-xl transition-all duration-300 ease-in-out z-40 overflow-hidden 
            ${isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4 pointer-events-none"}`}
            style={{
                width: "min(100%, 450px)", // Se adapta automáticamente al 100% o 350px máx.
                maxWidth: "calc(90vw - 1.5rem)", // Para pantallas chicas, ocupa un 90% del ancho
                height: "60vh", // Altura ajustada
            }}
        >
            {/* Encabezado fijo */}
            <div className="bg-green-500 text-white p-4 rounded-t-lg flex justify-between items-center sticky top-0 z-10">
                <h2 className="font-semibold">Contactate con nosotros</h2>
                <button onClick={onClose} className="text-white hover:bg-green-600 rounded-full p-1" aria-label="Cerrar chat">
                    <X size={20} />
                </button>
            </div>

            {/* Contenedor de mensajes con scrollbar personalizado */}
            <div
                className="p-4 overflow-y-auto flex-1 pt-[50px]"
                style={{
                    height: "calc(100% - 100px)", // Ajusta la altura restando los elementos fijos
                    scrollbarWidth: "thin",
                    scrollbarColor: "#22c55e transparent",
                }}
            >
                {messages.map((message) => (
                    <div key={message.id} className={`mb-4 flex ${message.isUser ? "justify-end" : "justify-start"}`}>
                        <div
                            className={`max-w-[90%] p-3 rounded-lg ${message.isUser ? "bg-green-100 text-gray-800" : "bg-white text-gray-800 border border-gray-200"
                                }`}
                        >
                            {message.text}
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            {/* Input y botón de enviar fijos abajo */}
            <form
                onSubmit={handleSendMessage}
                className="p-4 border-t flex items-center sticky bottom-0 bg-white z-10"
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Escribe un mensaje..."
                    className="flex-1 border rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <button type="submit" className="ml-2 bg-green-500 text-white p-2 rounded-full hover:bg-green-600" aria-label="Enviar mensaje">
                    <Send size={20} />
                </button>
            </form>

            {/* Estilos para el scrollbar */}
            <style>
                {`
                ::-webkit-scrollbar {
                    width: 2px;
                }
                ::-webkit-scrollbar-track {
                    background: transparent;
                }
                ::-webkit-scrollbar-thumb {
                    background: #22c55e;
                    border-radius: 10px;
                }
                `}
            </style>
        </div>
    );
}
