import styled from "styled-components"
import { colors } from "../../styles/stylesConfig";

const Loader = styled.div`

    @keyframes moving {
        50% {
            width: 100%;
        }

        100% {
            width: 0;
            right: 0;
            left: unset;
        }
    }

    display: block;
    --height-of-loader: 4px;
    --loader-color: ${colors.primary};
    width: 130px;
    height: var(--height-of-loader);
    border-radius: 30px;
    position: relative;

    &::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 80px;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;

}

`


export const Loader02 = () => {

    return (

        <Loader />

    )
}

export default Loader02;